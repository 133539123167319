<template>
    <v-container class="fade">
        <v-row class="primary-font mb-10 mt-3">
            <v-col cols="12" md="6" class="py-3 f14 d-flex flex-column justify-center">
                <span class="f20 fw600">The Civil Service Institute</span>
                <v-divider class="l-primary my-5" width="70%" />
                <p v-for="(paragraph, i) in paragraphs" :key="i">
                    {{ paragraph }}
                </p>
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                <v-img :src="require('@/assets/images/landing/csi-sample-photo.png')" contain/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            paragraphs: [
                'The mandate of the Civil Service Institute (CSI) is to drive institutional learning and development towards excellence in public service and administration in the Philippines by providing development interventions and opportunities for all employees of Government Agencies.',
                'CSI provides competency-based training and development as well as best practice consulting services to all civil servants and government agencies – specifically focusing on HR/OD and Leadership Capacity Development, and Foundation Programs – to support them in their function and in recognition of their ability to extend the work and mandate of CSI and ultimately influence the capabilities and capacities of civil servants.',
                'CSI also brokers competency development solutions by creating strategic partnerships and continuing engagement with thought leaders, learning process experts and talent managers across the bureaucracy, the academe and private organizations that help promote influx of new ideas and perspectives relevant to public service.',
                'Finally, CSI unifies public servant development by creating, managing and coordinating, as well as providing all these through deliberate approaches on research, knowledge management and learning technologies, and through key partnerships throughout the bureaucracy and external stakeholders.'
            ]
        })
    }
</script>